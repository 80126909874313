<template>
  <div>
    <!--Stats cards-->
    <!-- <div class="row"> -->
        <SalesFigures></SalesFigures>
    <!-- </div> -->

    <div class="row">
       <div class="col-lg-4 col-sm-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Sales by Month</h5>
            </div>
            <div class="card-body">
              <SalesByMonth></SalesByMonth>
            </div>
            <div class="card-footer">

            </div>
          </div>
       </div>
       <div class="col-lg-4 col-sm-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Sales by Person</h5>
            </div>
            <div class="card-body">
              <SalesByPerson></SalesByPerson>
            </div>
            <div class="card-footer">

            </div>
          </div>
       </div>
       <div class="col-lg-4 col-sm-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Sales Pipeline</h5>
            </div>
            <div class="card-body">
              <SalesPipeline></SalesPipeline>
            </div>
            <div class="card-footer">

            </div>
          </div>
       </div>
    </div>

    <div class="row">
       <div class="col-lg-4 col-sm-6">
          <div class="card">
            <!--  -->
            <div class="card-header">
              <h5 class="card-title">Sales by Publication</h5>
            </div>
            <div class="card-body">
              <SalesBySubProduction></SalesBySubProduction>
            </div>
            <div class="card-footer">

            </div>
          </div>
       </div>
    </div>


  </div>
</template>
<script>
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard'
  import {Badge} from 'src/components/UIComponents'
  import Loading from 'src/components/Dashboard/Layout/LoadingMainPanel.vue'

  import SalesByPerson from 'src/components/Dashboard/Views/Dashboard/dashboard-components/SalesByPerson'
  import SalesByMonth from 'src/components/Dashboard/Views/Dashboard/dashboard-components/SalesByMonth.js'
  import SalesPipeline from 'src/components/Dashboard/Views/Dashboard/dashboard-components/SalesPipeline.js'
  import SalesBySubProduction from 'src/components/Dashboard/Views/Dashboard/dashboard-components/SalesBySubProduction.js'

  // import TaskList from './Widgets/TaskList'
//!!
  import SalesFigures from 'src/components/Dashboard/Views/Dashboard/dashboard-components/SalesFigures'

  // const WorldMap = () => ({
  //   component: import('./../Maps/WorldMap.vue'),
  //   loading: Loading,
  //   delay: 200
  // })

  export default {
    components: {
      SalesByPerson,
      SalesByMonth,
      SalesBySubProduction,
      SalesPipeline,
      SalesFigures,
      StatsCard,
      ChartCard,
      CircleChartCard,
      // WorldMap,
      Badge
      // TaskList
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {


      }
    }
  }

</script>
<style>



</style>
