import { Doughnut, mixins } from 'vue-chartjs'
import * as CONSTS from '../../../../../consts/constants'
const { reactiveData } = mixins
export default {
  extends: Doughnut,
  mixins: [ reactiveData ],
  data () {
    return {
      chartData: null
    }
  },
  computed: {
    productionSelected () {
      return this.$store.getters.userSelectedProduction
    }
  },
  watch: {
    productionSelected (userSelectedProduction, oldValue) {
      // userSelectedProduction has changed (loaded)
      this.loadChartData()
    }
  },
  methods: {
    loadChartData: function () {
      let pid = this.$store.getters.userSelectedProduction
      let self = this


      if (pid != null) {
        this.$http.get('sales/salesperson/' + this.$store.getters.userSelectedProduction)
            .then(response => {
              let chartData = {
                labels: [],
                datasets: []
              }

              let innerDataArray = []
              var itemCount = response.data.length
              // console.log('SalesByPerson returned response:', response)

              for (var i = 0; i < itemCount; i++) {
                var obj = response.data[i]
                chartData.labels[i] = obj.Person
                innerDataArray[i] = obj.Total
              }

              var dataSet = {
                label: 'Sales by Person',
                backgroundColor: CONSTS.COLOUR_PALETTE,
                data: innerDataArray
              }

              chartData.datasets[0] = dataSet
              self.chartData = chartData
            })
            .catch(error => console.log(error))
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, {responsive: true, maintainAspectRatio: false})
    this.loadChartData()
  }
}
