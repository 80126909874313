import { Pie, mixins } from 'vue-chartjs'
import pluginChartText from './plugins/mm-plugin-chart-text'
const { reactiveProp } = mixins

const defaultOptions = {
  elements: {
    center: {
      text: '60%',
      color: '#66615c', // Default is #000000
      fontStyle: 'Arial', // Default is Arial
      sidePadding: 60 // Defualt is 20 (as a percentage)
    }
  },
  cutoutPercentage: 50,
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  }
}

export default {
  name: 'mm-simple-doughnut',
  extends: Pie,
  mixins: [reactiveProp],
  props: ['options'],
  props: {

    extraOptions: {
      type: Object,
      description: 'Chart.js options'
    }
  },

  data () {
    return {
      options : {
        cutoutPercentage: 70
      }
    }
  },

  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

    this.addPlugin(pluginChartText)

    let chartOptions = Object.assign(defaultOptions, this.extraOptions || {})
    // chartOptions.elements.center.text =  'bob' // this.data ? `${this.data[0]}%` : null;

    this.renderChart(this.chartData, chartOptions)
  }
}
