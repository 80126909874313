<template>
  <div>
   <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div class="card">
          <div class="card-header text-center">
              <h5 class="card-title">Sales Revenue</h5>
          </div>
          <div class="card-body">
            <SimpleDoughnutChart :chart-data="revenueDataCollection" :height="200" ></SimpleDoughnutChart>
          </div>
          <div class="card-footer">
           <hr>
            <div class="stats">
              Achieved: {{ revenueAchieved }}
              <br>
              Target: {{ revenueTarget }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="card">
          <div class="card-header text-center">
              <h5 class="card-title">Space</h5>
          </div>
          <div class="card-body">
            <SimpleDoughnutChart :chart-data="spaceDataCollection" :height="200" ></SimpleDoughnutChart>
          </div>
          <div class="card-footer">
           <hr>
            <div class="stats">
              Achieved: {{ pagesSold }}
              <br>
              Target: {{ pagesAvailable }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="card">
          <div class="card-header text-center">
              <h5 class="card-title">Forms</h5>
          </div>
          <div class="card-body">
            <SimpleDoughnutChart :chart-data="formsDataCollection" :height="200" ></SimpleDoughnutChart>
          </div>
          <div class="card-footer">
           <hr>
            <div class="stats">
              Complete: {{ completedParticipants }}
              <br>
              Incomplete: {{ incompleteParticipants }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONSTS from '../../../../../consts/constants'
import SimpleDoughnutChart from 'src/components/UIComponents/Charts/MMSimpleDoughnut.js'

var numeral = require("numeral");

export default {
  name: 'SalesFigures',
  components: {
      SimpleDoughnutChart
    },
  data () {
    return {

      revenueDataCollection: null,
      spaceDataCollection: null,
      formsDataCollection: null,

      revenueTarget: 0,
      pagesAvailable: 0,
      pagesSold: 0,
      revenueAchieved: 0,

      currency: '',
      // totalSold: 0,
      revPercentage: 0.00,
      // revenueTarget: 0,
      // totalSpace: 0,
      pagesPercentage: 0.00,
      // numPages: 0,
      formCloseDate: '',
      formCloseDaysToGo: 0,
      completedParticipants: 0,
      completedPercentage: 0.00,
      incompleteParticipants: 0
    }
  },
  computed: {
    productionSelected () {
      return this.$store.getters.userSelectedProduction
    }
  },
  watch: {
    productionSelected (userSelectedProduction, oldValue) {
      // userSelectedProduction has changed (loaded)
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      if (this.$store.getters.userSelectedProduction != null) {
        this.$http.get('sales/production/' + this.$store.getters.userSelectedProduction)
        .then(request => this.prodLoadSuccess(request))
        .catch(() => this.apiFailed())

        this.$http.get('production/stats/' + this.$store.getters.userSelectedProduction)
        .then(request => this.statsLoadSuccess(request))
        .catch(() => this.apiFailed())
      }
    },
    statsLoadSuccess: function (response) {
      // console.log(response.data)
      let itemCount = response.data.length
      for (var i = 0; i < itemCount; i++) {
        if (response.data[i].participant_entry_complete === 0) { // not complete
          this.incompleteParticipants = response.data[i].total
        }
        if (response.data[i].participant_entry_complete === 1) { //  complete
          this.completedParticipants = response.data[i].total
        }

        this.completedPercentage = this.percentCalculation(this.completedParticipants, this.completedParticipants + this.incompleteParticipants)

        let firstColour =  this.completedPercentage > 50 ? CONSTS.GREEN : CONSTS.RED;

        // console.log('forms complete %:', this.completedPercentage)

        this.formsDataCollection = {
          datasets: [
            {
              label: 'Forms Completed',
              //backgroundColor: '#f87979',
              //backgroundColor: ['#45B39D','#EC7063'],
              data: [this.completedPercentage, 100 - this.completedPercentage],
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [this.color || firstColour, this.secondaryColor || '#f4f3ef'],
              borderWidth: 0,
            }
          ]
        }

      }
    },
    prodLoadSuccess: function (response) {
      let momentDate = ''
      // console.log(response.data[0].production_forms_close)
      try {

        momentDate = this.$moment(response.data[0].production_forms_close)

        this.formCloseDate = momentDate.format('dddd, MMMM Do YYYY')

        var now = this.$moment()
        this.formCloseDaysToGo = this.$moment(momentDate).diff(now, 'days')

        this.currency = response.data[0].ccy_symbol
        this.revenueTarget = response.data[0].revenue_target
        this.pagesAvailable = response.data[0].pages_available
        this.pagesSold = response.data[0].pages_sold
        this.revenueAchieved = response.data[0].revenue_achieved

        this.yield = response.data[0].yield

        var financialFormat = new String('0,0.00')

        // calc percentages
        this.revPercentage = this.percentCalculation(this.revenueAchieved, this.revenueTarget)
        this.pagesPercentage = this.percentCalculation(this.pagesSold, this.pagesAvailable)

        this.revenueTarget = this.currency + numeral(this.revenueTarget).format(financialFormat)
        this.revenueAchieved = this.currency + numeral(this.revenueAchieved).format(financialFormat)

        // console.log('this.pagesPercentage',this.pagesPercentage, response.data[0].pages_available, response.data[0].pages_sold)

        let firstColour = '#CD6155'  //#4acccd

        // '#5DADE2'

        firstColour =  this.pagesPercentage > 50 ? CONSTS.GREEN : CONSTS.RED;

        // chartOptions.elements.center.text =  this.data ? `${this.data[0]}%` : null;

        this.spaceDataCollection = {
          datasets: [
            {
              label: 'Sales Revenue',
              // backgroundColor: '#f87979',
              // backgroundColor: ['#45B39D','#EC7063'],
              data: [this.pagesPercentage, 100 - this.pagesPercentage],
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [this.color || firstColour, this.secondaryColor || '#f4f3ef'],
              borderWidth: 0,
            }
          ]
        }

        firstColour =  this.revPercentage > 50 ? CONSTS.GREEN : CONSTS.RED;

        this.revenueDataCollection = {
          datasets: [
            {
              label: 'Sales Revenue',
              //backgroundColor: '#f87979',
              //backgroundColor: ['#45B39D','#EC7063'],
              data: [this.revPercentage, 100 - this.revPercentage],
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [this.color || firstColour, this.secondaryColor || '#f4f3ef'],
              borderWidth: 0,
            }
          ]
        }

      } catch (err) {
        console.log(err.message)
      }
    },
    getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      },
    apiFailed: function () {
    },
    percentCalculation: function (a, b) {
       console.log(a,b)

      if (b <= 0 || a === undefined | b === undefined | a === null | b === null | Number.isNaN(Number(a)) === true || Number.isNaN(Number(b)) === true) {
        return 0.00
      }

      var numerator = a * 100
      let demominator = b * 100
      let calculatedPercent = 0

      calculatedPercent = (numerator / demominator) * 100
      calculatedPercent = ((calculatedPercent).toFixed(2))
      calculatedPercent = parseFloat(calculatedPercent)
      return calculatedPercent
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
