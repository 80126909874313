export default {
  id: 'text-plugin',


  // beforeInit: function(chart) { console.log('chart-plugin 1') },
  // afterInit: function(chart) { console.log('chart-plugin 2') },
  // beforeUpdate: function(chart) { console.log('chart-plugin 3') },
  // afterUpdate: function(chart) { console.log('chart-plugin 4') },
  // beforeLayout : function(chart) { console.log('chart-plugin 5') },
  // afterLayout: function(chart) { console.log('chart-plugin 6') },
  // beforeDatasetsUpdate : function(chart) { console.log('chart-plugin 7') },
  // afterDatasetsUpdate: function(chart) { console.log('chart-plugin 8') },
  // beforeDatasetUpdate : function(chart) { console.log('chart-plugin 9') },
  // afterDatasetUpdate: function(chart) { console.log('chart-plugin 10') },
  // beforeRender : function(chart) { console.log('chart-plugin 11') },
  // afterRender: function(chart) { console.log('chart-plugin 12') },
  // beforeDraw : function(chart) { console.log('chart-plugin 13');  },
  afterDraw: function(chart) {  this.renderLabel(chart); },
  // beforeDatasetsDraw : function(chart) { console.log('chart-plugin 15') },
  // afterDatasetsDraw: function(chart) { console.log('chart-plugin 16') },
  // beforeDatasetDraw : function(chart) { console.log('chart-plugin 17') },
  // afterDatasetDraw: function(chart) { console.log('chart-plugin 18');  },
  // beforeEvent : function(chart) { console.log('chart-plugin 19') },
  // afterEvent: function(chart) { console.log('chart-plugin 20')  },


  renderLabel: function(chart) {



    if (chart.data.datasets.length > 0){
      // console.log('renderLabel have data:', chart.data)
    }else{
      // console.log('renderLabel no data')
      return
    }


    if (chart.data.datasets[0].data[0]) {

      chart.config.options.elements.center =
      {
        text: '60%',
        color: '#66615c', // Default is #000000
        fontStyle: 'Arial', // Default is Arial
        sidePadding: 60, // Defualt is 20 (as a percentage)
        text: chart.data.datasets[0].data[0] + '%'
      }

      //Get ctx from string
      let ctx = chart.chart.ctx;

      //Get options from the center object in options
      let centerConfig = chart.config.options.elements.center;

      let fontStyle = centerConfig.fontStyle || 'Arial';
      let txt = centerConfig.text;
      let color = centerConfig.color || '#000';
      let sidePadding = centerConfig.sidePadding || 20;
      let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      //Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      let stringWidth = ctx.measureText(txt).width;
      let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      let widthRatio = elementWidth / stringWidth;
      let newFontSize = Math.floor(30 * widthRatio);
      let elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      let fontSizeToUse = Math.min(newFontSize, elementHeight);

      //Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      //Draw text in center
      ctx.fillText(txt, centerX, centerY);

      //console.log('beforeDraw',txt, centerX, centerY)
    }
  }
}
