import { PolarArea, mixins } from 'vue-chartjs'
import * as CONSTS from '../../../../../consts/constants'
const { reactiveData } = mixins
export default {
  extends: PolarArea,
  mixins: [ reactiveData ],
  data () {
    return {
      chartData: null
    }
  },
  computed: {
    productionSelected () {
      return this.$store.getters.userSelectedProduction
    }
  },
  watch: {
    productionSelected (userSelectedProduction, oldValue) {
      // userSelectedProduction has changed (loaded)
      this.loadChartData()
    }
  },
  methods: {
    loadChartData: function () {
      let pid = this.$store.getters.userSelectedProduction
      let self = this
      // console.log(this.$store.getters.userSelectedProduction)
      if (pid != null) {
        this.$http.get('sales/pipeline/' + this.$store.getters.userSelectedProduction)
            .then(response => {
              let chartData = {
                labels: [],
                datasets: []
              }

              let innerDataArray = []
              var itemCount = response.data.length
              // console.log('SalesPipeline returned response:', response)

                // [{"total":1000,"probability":0},{"total":10000,"probability":2}]

              for (var i = 0; i < itemCount; i++) {
                var obj = response.data[i]


                chartData.labels[i] = CONSTS.strings.probability[obj.probability].text
                innerDataArray[i] = obj.total
              }

              var dataSet = {
                label: 'Sales Pipeline',
                backgroundColor: CONSTS.COLOUR_PALETTE,
                data: innerDataArray
              }

              chartData.datasets[0] = dataSet
              self.chartData = chartData
            })
            .catch(error => console.log(error))
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, {responsive: true, maintainAspectRatio: false})
    this.loadChartData()
  }
}
